import { inject, Injectable } from '@angular/core';
import { AbstractService } from '@core/abstract.service';
import { FuseUtilsService } from '@fuse/services/utils';
import { ApiService } from '@targx/index';
import { map, Observable, of, tap } from 'rxjs';
import { PaginatedRequest, Request } from './request';

@Injectable({
  providedIn: 'root'
})
export class RequestService extends AbstractService<Request, PaginatedRequest> {
  private _api = inject(ApiService);
  private _utils = inject(FuseUtilsService);
  constructor() {
    super();
  }

  public findAll$(args?: any): any {
    return this._api
      .post<PaginatedRequest>('/v1/requests/find-all', {
        ...args,
      })
      .pipe(
        map((response: PaginatedRequest) => {
          return new PaginatedRequest(response);
        }),
        tap(response => {
          this.pagination = response.meta;
          this.items = response.items;
        })
      );
  }

  public findAll(args?: any): any {
    return this._api
      .post<PaginatedRequest>('/v1/requests/find-all', {
        ...args,
      })
      .pipe(
        map((response: PaginatedRequest) => {
          return new PaginatedRequest(response);
        })
      );
  }

  public findOne(id: number): any {
    if (!id) {
      return of(new Request());
    }

    return this._api.get<Request>(`/v1/requests/${id}`).pipe(
      map((response: Request) => {
        return response;
      }),
      tap(response => {
        this.item = response;
      })
    );
  }

  public approve(id: number, args: any): Observable<any> {
    return this._api
      .patch(`/v1/requests/${id}/approve`, args)
      .pipe(
        map((response: any) => {
          this.refresh = true;
          return response;
        })
      );
  }

  public reject(id: number, args: any): Observable<any> {
    return this._api
      .patch(`/v1/requests/${id}/reject`, args)
      .pipe(
        map((response: any) => {
          this.refresh = true;
          return response;
        })
      );
  }

  public updateRequest(id: number, args: any): Observable<any> {
    return this._api.patch<any>(`/v1/requests/${id}`, args).pipe(
      map((response: any) => {
        this.refresh = true;
        return response;
      })
    );
  }

  getTotalRequestsByStatusOpen(): Observable<any> {
    return this._api.get<any>('/v1/requests/total-open').pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  requestService
}
