import { Optional } from "@angular/core";
import { AbstractModel } from "@core/base-model";
import { Paginated } from "@targx/index";

export class Request extends AbstractModel {

  [key: string]: any;

  constructor(
    id?: number
  ) {
    super();
  }

  bootstrap() {
  }
}

export class PaginatedRequest extends Paginated(Request) {
  constructor(@Optional() data: PaginatedRequest) {
    data.items = data.items.map(item => {
      item = Object.assign(new Request(), item);
      item.bootstrap();
      return item;
    });
    super(data.items, data.meta);
  }
}
